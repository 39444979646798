<template>
  <div>
    <!-- header row -->
    <b-row class="content-header">
      <b-col cols="12">
        <div class="breadcrumb-wrapper">
          <b-breadcrumb>
            <b-breadcrumb-item :to="{ name: 'dashboard' }">
              <feather-icon
                icon="HomeIcon"
                size="16"
                class="align-text-top"
              /> Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'registration-report' }">
              Users
            </b-breadcrumb-item>
            <b-breadcrumb-item :active="true">
              Registration Report
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </b-col>
      <b-col md="6">
        <h2 class="p-2">
          <feather-icon
            icon="UserPlusIcon"
            size="24"
          />
          Registration Report
        </h2>
      </b-col>
      <b-col
        md="6"
        class="mb-2 text-right pt-2"
      >
      </b-col>
    </b-row>

    <!-- error message -->
    <section v-if="errorMessage">
      <b-card>
        <b-alert
          variant="danger"
          show
        >
          <div class="alert-body">
            {{ errorMessage }}
          </div>
        </b-alert>
      </b-card>
    </section>

    <!-- registrations report table -->
    <section v-if="visibleRegistrations">
      <!-- table row  -->
      <b-card>
        <b-row v-if="visibleRegistrations.length <= 0">
          <b-col>
            No items to show.
          </b-col>
        </b-row>
        <b-row v-if="visibleRegistrations.length > 0">
          <b-col
            md="2"
            sm="4"
            class="my-1"
          >
            <b-form-group class="mb-0">
              <label
                class="d-block text-sm-left"
                for="perPageSelect"
              >Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
                @change="handlePerPage"
              />
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            sm="8"
            offset="6"
            class="my-1"
          >
            <b-form-group class="mb-0">
              <label
                class="d-block text-sm-left"
                for="sortBySelect"
              >Sort</label>
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                  class="w-75"
                >
                  <template v-slot:first>
                    <option value="">
                      Select
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                  class="w-25"
                >
                  <option :value="false">
                    Asc
                  </option>
                  <option :value="true">
                    Desc
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- <b-col
            md="6"
            class="my-1"
          >
            <b-form-group class="mb-0">
              <label
                class="d-block text-sm-left"
                for="filterInput"
              >Filter</label>
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col> -->

          <!--
              :per-page="perPage"
              :current-page="currentPage"
          -->
          <b-col cols="12 mt-2">
            <b-table
              hover
              responsive
              :items="visibleRegistrations"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #cell(campaign_name)="data">
                <b-link :to="{ name: 'view-question', params: { id: data.item.id } }">
                  {{ data.item.campaign_name }}
                </b-link>
              </template>

              <template #cell(type)="data">
                <b-badge
                  pill
                  variant="light-primary"
                >
                  {{ data.item.type }}
                </b-badge>
              </template>

              <template #cell(open_date)="data">
                {{ convertToLocalTime(data.item.open_date) }}
              </template>

              <template #cell(cutoff_date)="data">
                {{ convertToLocalTime(data.item.cutoff_date) }}
              </template>

              <template #cell(close_date)="data">
                {{ convertToLocalTime(data.item.close_date) }}
              </template>

              <template #cell(closed)="data">
                <b-badge
                  pill
                  :variant="closedStatusColor[data.item.closed] || 'light-secondary'"
                >
                  <span v-if="data.item.closed">Closed</span>
                  <span v-else>Open</span>
                </b-badge>
              </template>

              <template #cell(actions)="data">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>

                    <b-dropdown-item
                      @click="handleItemTrue(data.item.id)"
                    >
                      <feather-icon
                        icon="CheckCircleIcon"
                        class="mr-50"
                      />
                      <span>Set as True</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      @click="handleItemFalse(data.item.id)"
                    >
                      <feather-icon
                        icon="XCircleIcon"
                        class="mr-50"
                      />
                      <span>Set as False</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      @click="handleItemEdit(data.item)"
                    >
                      <feather-icon
                        icon="EditIcon"
                        class="mr-50"
                      />
                      <span>Edit Question</span>
                    </b-dropdown-item>

                    <!-- <b-dropdown-item
                      @click="handleItemDelete(data.item.id)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                      />
                      <span>Delete</span>
                    </b-dropdown-item> -->
                  </b-dropdown>
                </span>
              </template>

            </b-table>
          </b-col>

          <b-col
            cols="12"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalTableRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
              @page-click="handlePaginationClick"
            />
          </b-col>
        </b-row>
      </b-card>
    </section>
  </div>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol,
  BAlert,
  BBadge, // BButton,
  BCard,
  // BCardText,
  BDropdown, BDropdownItem,
  // BForm, BFormInput,
  BFormGroup, BFormSelect,
  BInputGroup, // BInputGroupAppend,
  BLink,
  BPagination, BTable,
  // BModal,
} from 'bootstrap-vue'

import { required } from '@validations'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
// import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BLink,
    BPagination,
    BTable,
    BCard,
    // validations
    // ValidationProvider,
    // ValidationObserver,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      adminAccess: false,
      adminSuffix: '', // 'AsRoot' if root user

      perPage: 10,
      pageOptions: [5, 10, 15, 30, 45, 75],
      // totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'id', label: 'Id', sortable: true },
        { key: 'first_name', label: 'First Name', sortable: true },
        { key: 'last_name', label: 'Last Name', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'mobile', label: 'Mobile', sortable: false },
        {
          key: 'dob', label: 'Date of Birth', sortable: true, formatter: this.dobFormatter,
        },
        { key: 'role', label: 'Role', sortable: true },
        {
          key: 'created_at', label: 'Date Registered', sortable: true, formatter: this.createdAtFormatter,
        },
        {
          key: 'verified_at', label: 'Date Verified', sortable: true, formatter: this.createdAtFormatter,
        },
      ],
      statusColor: {
        approved: 'light-success',
        flagged: 'light-warning',
        new: 'light-info',
        rejected: 'light-danger',
      },
      closedStatusColor: {
        false: 'light-success',
        true: 'light-danger',
      },
      errorMessage: null,

      // validation
      required,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    visibleRegistrations() {
      const digestData = this.$store.getters['registration/getRegistrationReport']
      return digestData ? digestData.data : []
    },
    totalTableRows() {
      const digestData = this.$store.getters['registration/getRegistrationReport']
      const linksData = digestData ? digestData.links : 0
      return linksData ? linksData.current_size * linksData.last_page : 0
    },
  },
  created() {
    this.getRegistrationReport()
  },
  methods: {
    getRegistrationReport(args) {
      // set paging
      const reportArgs = {
        page: this.currentPage,
        size: this.perPage,
        ...args,
      }
      this.$store.dispatch('registration/fetchRegistrationReport', reportArgs)
    },

    /* TABLE FILTER AND PAGINATION */

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // this.totalRows = filteredItems.length
      this.perPage = filteredItems.length
      this.currentPage = 1
      this.getRegistrationReport()
    },
    handlePaginationClick(bvEvent, currentPage) {
      const conArgs = {
        page: currentPage,
      }
      this.getRegistrationReport(conArgs)
    },
    handlePerPage(sizeValue) {
      const conArgs = {
        size: sizeValue,
      }
      this.getRegistrationReport(conArgs)
    },

    /* MISCELLANEOUS */

    convertToLocalTime(dateStr) {
      // convert utc date to local time
      const utcDate = moment.utc(dateStr)
      const convertedDate = moment(utcDate).local().format('YYYY-MM-DD h:mm A')
      return convertedDate
    },
    convertToUTC(dateStr) {
      // convert date to UTC
      const localDate = moment(dateStr).local()
      const convertedDate = moment.utc(localDate).format('YYYY-MM-DD HH:mm')
      return convertedDate
    },
    dobFormatter(dateStr) {
      // truncate time from datetime string
      return moment(dateStr).format('YYYY-MM-DD')
    },
    createdAtFormatter(dateStr) {
      // truncate seconds from datetime string
      return moment(dateStr).format('YYYY-MM-DD HH:mm')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
